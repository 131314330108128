// Define themes as mixins for easy application
@mixin default-theme {
    --background-color: #fff;
    --text-color: #333;
    --button-bg-color: #6200ee;
    --button-text-color: #fff;
  }
  
  @mixin dark-theme {
    --background-color: #333;
    --text-color: #ccc;
    --button-bg-color: #3700B3;
    --button-text-color: #fff;
  }
  
  // Apply the default theme
  :root {
    @include default-theme;
  }
  
  // Apply the dark theme
  [data-theme="dark"] {
    @include dark-theme;
  }
  