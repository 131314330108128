body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 
    "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markdown > * {
  all: revert;
  line-height: 1.3;
}

.markdown > ul {
  color: inherit;
}

.markdown > ul li {
  color: inherit;
  font-size: 0.94em;
  line-height: 1.2;
}

.markdown > ul li::marker {
  color: inherit;
}

.markdown table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}

.markdown th, .markdown td {
  border: 1px solid #E1DFDD;
  border-radius: 2px;
  padding: 8px;
  text-align: left;
}